<mat-form-field fxFlex="calc(50% - 16px)">
  <mat-label>{{ to.label }}</mat-label>
  <input
    [formControl]="formControl"
    matInput
    [required]="to.required ? true : false"
    [max]="maxDate"
    [matDatepicker]="picker"
    (dateChange)="formatDate($event)"
    [errorStateMatcher]="dobErrorStateMatcher"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-error>This field is required</mat-error>
</mat-form-field>
