<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    conditionTypeTags: (conditionTypeTags$  | async) ?? []
  }"
></ng-container>

<ng-template
  #template
  let-conditionTypeTags="conditionTypeTags"
>
<h2 class=>{{to.description}}</h2>
<div class="tag-container">
  <div
    *ngFor="let tag of conditionTypeTags; let i = index"
    class="tag"
    (click)="conditionTypeSelected(tag)"
    [ngClass]="{ 'selected': tag === selectedTag }"
  >
    {{ tag.displayName }}
  </div>
</div>
</ng-template>
