import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { MtxPopoverModule } from "@ng-matero/extensions/popover";
import { SignalRServiceModule } from "@vp/shared/signal-r-service";
import { NgxPermissionsModule } from "ngx-permissions";
import { TopNavUserComponent } from "./top-nav-user.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatTooltipModule,
    MtxPopoverModule,
    MatIconModule,
    SignalRServiceModule,
    NgxPermissionsModule.forChild()
  ],
  declarations: [TopNavUserComponent],
  exports: [TopNavUserComponent]
})
export class TopNavUserModule {}
