<div
  [fxLayout]="to.fxLayout ?? 'row wrap'"
  fxLayout.lt-md="column"
  [fxLayoutAlign]="to.fxLayoutAlign ?? ''"
  [fxLayoutGap]="to.fxLayoutGap ?? '1rem grid'"
>
  <ng-container *ngFor="let f of field.fieldGroup; trackBy: trackById">
    <formly-field [fxFlex]="getFlex(f)" [field]="f"></formly-field>
  </ng-container>
</div>
