/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { QuickSearchModule } from "@vp/shared/components/quick-search";
import { TagTypeSelectorModule } from "@vp/shared/components/tag-type-selector";
import { MaterialModule } from "@vp/shared/material";
import { TagTypePipe } from "@vp/shared/pipes/context-display";
import { FilterTermPipeModule } from "@vp/shared/pipes/filter-term";
import { UtilitiesModule } from "@vp/shared/utilities";
import { NgxPermissionsModule } from "ngx-permissions";
import { UserAssignTagsComponent } from "./user-assign-tags.component";

@NgModule({
  imports: [
    CommonModule,
    FilterTermPipeModule,
    MatDialogModule,
    MaterialModule,
    NgxPermissionsModule,
    QuickSearchModule,
    TagTypeSelectorModule,
    UtilitiesModule
  ],
  exports: [UserAssignTagsComponent],
  declarations: [UserAssignTagsComponent],
  providers: [TagTypePipe]
})
export class UserAssignTagsModule {}
