/* eslint-disable no-console */
import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, ChangeDetectionStrategy, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { FieldArrayType, FormlyFieldConfig } from "@ngx-formly/core";
import { map } from "rxjs/operators";

@Component({
  selector: "lib-string-array-edit-type",
  templateUrl: "./string-array-edit-type.component.html",
  styleUrls: ["./string-array-edit-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StringArrayEditTypeComponent extends FieldArrayType implements OnInit {
  value = "";
  validForm = false;
  dataSource: any = [];

  private mobileColumns = ["value", "actions"];
  private desktopColumns = ["value", "actions"];
  displayedColumns = this.breakpointObserver.observe(["(max-width: 959px)"]).pipe(
    map(state => {
      if (state.matches) {
        return this.mobileColumns;
      } else {
        return this.desktopColumns;
      }
    })
  );

  ngOnInit(): void {
    this.setDataSource();
  }

  setDataSource() {
    if (Array.isArray(this.model)) {
      var tempModel = this.model.map((element: any, index: number) => {
        return { index: index, value: element };
      });
      this.dataSource = new MatTableDataSource(tempModel);
    }
  }

  constructor(private breakpointObserver: BreakpointObserver) {
    super();
  }

  trackById(index: number, item: FormlyFieldConfig) {
    return item.id ?? index;
  }

  applyFilter() {
    this.validForm = this.value === "" ? false : true;

    this.model.forEach((element: string) => {
      if (element.toLowerCase() === this.value.toLowerCase()) this.validForm = false;
    });

    this.dataSource.filter = this.value.trim().toLowerCase();
  }

  submit() {
    this.add(0, this.value);
    this.value = "";
    this.setDataSource();
  }
}
