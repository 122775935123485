import { InjectionToken } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import * as ApplicationStateActions from "@vp/data-access/application";
import * as OrganizationActions from "@vp/data-access/organization";
import * as UserStateActions from "@vp/data-access/users";
import { Organization, User } from "@vp/models";
import { AccessControlService } from "@vp/shared/access-control";
import { AuthenticationService } from "@vp/shared/authentication";
import { FeatureConfig, FeatureService } from "@vp/shared/features";
import { LocalStorageService } from "@vp/shared/local-storage";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { AppStoreService } from "@vp/shared/store/app";
import { UiDisplayTagService } from "@vp/shared/store/ui";
import { combineLatest } from "rxjs";
import { catchError, concatMap, take, tap, withLatestFrom } from "rxjs/operators";

export const ROUTE_UNINVITED = "uninvited";
export const ROUTE_ERROR = "error";
export const CONFIG_DEPENDENCIES = new InjectionToken<(() => unknown)[]>("CONFIG_DEPENDENCIES");

// WARNING! These DI properties need to match deps in the app.module
// IN THE EXACT ORDER

export function appInitializerFactory(
  accessControlService: AccessControlService,
  appStoreService: AppStoreService,
  authenticationService: AuthenticationService,
  _configDeps: (() => unknown)[],
  featureService: FeatureService,
  isIvyApi: boolean,
  localStorageService: LocalStorageService,
  permConst: PermissionsConstService,
  router: Router,
  store: Store,
  uiDisplayTagService: UiDisplayTagService
): () => Promise<void> {
  /* APP_INITIALIZER requires a Promise to resolve*/

  return (): Promise<void> =>
    new Promise<boolean>(resolve => {
      authenticationService
        .checkAuth()
        .pipe(withLatestFrom(authenticationService.getAccount()), take(1))
        .subscribe(([loginResponse, userDataResult]) => {
          //NOT AUTHENTICATED
          if (!loginResponse.isAuthenticated) {
            const pathname = window.location.pathname;
            if (
              pathname !== "/" &&
              pathname !== "/home" &&
              pathname !== "/loggedout" &&
              pathname !== "/login/callback"
            ) {
              localStorageService.set({
                data: {
                  redirect: true,
                  pathname: window.location.pathname,
                  search: window.location.search,
                  fragment: window.location.hash
                }
              });
            }
            resolve(false);
          }
          // AUTHENTICATED
          else {
            if (userDataResult || isIvyApi) {
              accessControlService.initNgxPermissions();
              combineLatest([
                appStoreService.loadLoginUser().pipe(
                  filterNullMap(),
                  concatMap(user => {
                    store.dispatch(new OrganizationActions.LoadOrganization());
                    store.dispatch(
                      new ApplicationStateActions.PatchState({
                        isBrowserOnline: navigator.onLine
                      })
                    );
                    return [user];
                  })
                ),
                appStoreService.organization$.pipe(filterNullMap())
              ])
                .pipe(
                  tap(([user, organization]: [User, Organization]) => {
                    appStoreService.setOrganization(organization);
                    appStoreService.setUserRoles();
                    appStoreService.setUserDepartments(user);
                    store.dispatch(new UserStateActions.SetCurrentUser(user.userId));
                    permConst.check(organization.permissionTags);
                    uiDisplayTagService.overrideDefaultDisplayTags(organization.displayTags);
                    organization.features?.forEach((feature: FeatureConfig) => {
                      featureService.add(feature);
                    });
                  }),
                  take(1),
                  catchError(error => {
                    /**
                     * TODO: Not sure if this is the best place to do this here. But logically, any errors
                     * thrown in this stream would be considered catastropic and should the site should halt
                     * loading. That said this could perhaps be expanded on to throw a custom "halt" exception
                     * could happen in a service that does more with the error condition.
                     */
                    if (
                      typeof error?.error === "string" &&
                      error.error?.includes("User must be invited to use the system")
                    ) {
                      return router.navigate([ROUTE_UNINVITED]);
                    }
                    return router.navigate([ROUTE_ERROR]);
                  })
                )
                .subscribe(() => {
                  resolve(true);
                });
            }
          }
        });
    }).then(() => {
      router.initialNavigation();
    });
}
