<button mat-flat-button color="primary" (click)="onClick($event)">{{ to.label }}</button>

<ng-template #modalWrapper>
  <div fxFill fxLayout="column" fxLayoutAlign="space-between stretch">
    <h1 mat-dialog-title>{{ to.title }}</h1>
    <div mat-dialog-content>
      <ng-container #fieldComponent></ng-container>
    </div>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <button type="button" mat-button (click)="onClose($event)">Cancel</button>
      <button
        type="button"
        mat-raised-button
        color="primary"
        [disabled]="formControl.invalid"
        (click)="onCommit($event)"
      >
        Commit
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>
