<div *ngIf="pageMenuItems$ | async as pageMenuItems" flexLayout="row" flexLayoutAlign="end center">
  <!-- TODO Adjust number and format of buttons depending on count, i.e. add a triple-dot menu when more than 3 buttons present -->
  <ng-container *ngFor="let pageMenuItem of pageMenuItems">
    <ng-container *ngxPermissionsOnly="pageMenuItem.permissions ?? []">
      <a
        flexShow
        color="primary"
        (click)="navigate(pageMenuItem.routerLink, pageMenuItem.queryParams)"
      >
        {{ uiDisplayTagService.displayTags[pageMenuItem.label ?? ""] || pageMenuItem.label }}
      </a>
      <a
        *ngIf="pageMenuItem.icon"
        flexHide
        [routerLink]="pageMenuItem.routerLink"
        [queryParams]="pageMenuItem.queryParams"
      >
        <mat-icon>
          {{ pageMenuItem.icon }}
        </mat-icon>
      </a>
    </ng-container>
  </ng-container>
</div>
