<mat-toolbar class="menu site-header" fxLayout="row" fxLayoutAlign="space-between stretch">
  <div fxFlex fxLayout="row " fxLayoutAlign="start stretch">
    <vp-top-nav-logo
      class="logo"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxShow
      fxHide.lt-md
    ></vp-top-nav-logo>
    <vp-top-nav-list
      fxLayout="row"
      fxLayoutAlign="start stretch"
      [menuOption]="false"
      [menuItems]="(menuItems$ | async) ?? []"
    ></vp-top-nav-list>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1rem">
    <vp-top-nav-search fxShow fxFlex.lt-md></vp-top-nav-search>
    <vp-top-nav-user></vp-top-nav-user>
    <button
      *ngIf="showExtendedMenu | async"
      mat-icon-button
      type="button"
      color="primary"
      [matMenuTriggerFor]="dropMenu"
    >
      <mat-icon inline="true">menu</mat-icon>
    </button>
    <mat-menu #dropMenu="matMenu">
      <vp-top-nav-search [fxShow]="false"></vp-top-nav-search>
      <button
        *ngFor="let item of (menuItems$ | async) ?? []"
        mat-menu-item
        type="button"
        routerLinkActive="active"
        [routerLink]="item.routerLink"
      >
        {{ item?.displayName }}
      </button>
    </mat-menu>
  </div>
</mat-toolbar>

<div *ngIf="isLoading$ | async" class="center">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
