<div fxLayout="row" fxLayoutGap="1rem">
  <mat-form-field>
    <mat-label>Templates</mat-label>
    <mat-select [(value)]="selectedSnippet">
      <mat-option (click)="addNewSnippet()">Add New</mat-option>
      <mat-option *ngFor="let snippet of snippets$ | async" [value]="snippet">
        {{ snippet.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button
    mat-raised-button
    (click)="insertSnippet()"
    fxFlexAlign="center"
    color="primary"
    [disabled]="selectedSnippet === undefined"
  >
    Insert
  </button>
</div>
