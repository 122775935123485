import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { ASSIGNMENT_MODAL_OPTIONS } from "@vp/shared/assignments/models";
import { QuickSearchModule } from "@vp/shared/components/quick-search";
import { MaterialModule } from "@vp/shared/material";
import { ContextDisplayModule } from "@vp/shared/pipes/context-display";
import { FilterTermPipeModule } from "@vp/shared/pipes/filter-term";
import { UserAssignRolesComponent } from "./user-assign-roles.component";

@NgModule({
  imports: [
    CommonModule,
    FilterTermPipeModule,
    MaterialModule,
    QuickSearchModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    ContextDisplayModule
  ],
  exports: [UserAssignRolesComponent],
  declarations: [UserAssignRolesComponent],
  providers: [
    {
      provide: ASSIGNMENT_MODAL_OPTIONS,
      useValue: {
        columns: [
          {
            field: "displayName",
            header: "Display Name"
          },
          {
            field: "description",
            header: "description"
          }
        ],
        title: "Assign Tags",
        config: {
          disableClose: true,
          autoFocus: false,
          closeOnNavigation: true,
          width: "60vw"
        }
      }
    }
  ]
})
export class UserAssignRolesModule {}
