import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { NgxPermissionsModule } from "ngx-permissions";
import { PageMenuComponent } from "./page-menu.component";

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule, NgxPermissionsModule, RouterModule],
  declarations: [PageMenuComponent],
  exports: [PageMenuComponent]
})
export class PageMenuModule {}
