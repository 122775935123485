import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSidenavModule } from "@angular/material/sidenav";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { DynamicTemplateModule } from "@vp/shared/directives/dynamic-template";
import { MaterialModule } from "@vp/shared/material";
import { MemoizePipeModule } from "@vp/shared/pipes/memoize";
import { SpinnerModule } from "@vp/shared/spinner";
import { ViewContentPageComponent } from "./view-content-page.component";

@NgModule({
  imports: [
    CommonModule,
    AngularEditorModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SpinnerModule,
    MemoizePipeModule,
    MatSidenavModule,
    DynamicTemplateModule
  ],
  declarations: [ViewContentPageComponent]
})
export class ViewContentPageModule {}
