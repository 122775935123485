import { IDocument, LayoutConfigOption, TagFilter } from "@vp/models";
import { JSONSchema7 } from "json-schema";
import { GroupRef } from "./group";
import { DeviceAssignmentData, PatientConsoleDeviceData } from "./patient-console-device-data";
import { Profile } from "./profile";
import { UserRole } from "./user-role";

export interface User extends IDocument {
  userId: string;
  userType: UserType;
  isOnline: boolean;
  email: string;
  profile: Profile;
  roles: UserRole[];
  groups: GroupRef[];
  active: boolean;
  tags: string[];
  assignedTags: string[];
  selectedRoleId: string;
  lastLoginDateTime?: string;
  invitationEmail?: UserInvitationEmail;
  userData?: UserData;
  patientConsoleDeviceData?: PatientConsoleDeviceData;
  zoomCallData?: Partial<ZoomCallData>;
}

export interface UserInvitationEmail {
  subject: string;
  body: string;
}

export interface UserType {
  userTypeId: string;
  friendlyId: UserTypeFriendlyId;
  displayName: string;
}

export enum UserTypeFriendlyId {
  loginUser = "login-user",
  nonLoginUser = "non-login-user",
  device = "device",
  acesoTv = "aceso-tv"
}

export interface UserData {
  snippets?: Snippet[];
  tagFilters?: TagFilter[];
  flags: { [key: string]: boolean };
  [key: string]: any;
}

export interface Snippet {
  title: string;
  text: string;
}

/**@deprecated
 * usages of this should be replaced with just the id of the user and resolved when used.
 * */
export interface UserLite {
  email: string;
  firstName: string;
  lastName: string;
  salutation: string;
  userId: string;
  isOnline: boolean;
  deviceAssignmentData?: DeviceAssignmentData | null;
}

export interface Flag {
  [key: string]: boolean;
}

export interface UserTypeConfig {
  type: string;
  singleRoleAssignment: boolean;
  singleDepartmentAssignment: boolean;
  assignableRoleFriendlyId: string[];
  assignableDepartmentFriendlyId: string[];
  userSchema: JSONSchema7;
  userLayout: LayoutConfigOption;
}

export interface ZoomCallData {
  active: boolean;
  applicationHostId: string;
  topic: string;
  lastUpdatedBy: string;
  lastUpdatedDateTime: string;
}
