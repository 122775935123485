import { JSONSchema7, JSONSchema7Array, JSONSchema7Object } from "json-schema";
import _ from "lodash";
import { isObject } from "./utilities";

/**
 * Get the value (or count for arrays) for the path from a complex object
 * @param complexObject Usually JSON data as a complex object
 * @param dotPath Property path in dot notation e.g "mysection.myproperty"
 */
export function getDataValueFromDotPath(
  complexObject: Record<string, unknown>,
  dotPath: string
): any {
  const props = dotPath.split("."); // split property names

  for (let i = 0; i < props.length; i++) {
    if (typeof complexObject !== "undefined") {
      complexObject = complexObject[props[i]] as Record<string, unknown>; // go next level
    }
  }
  if (Array.isArray(complexObject)) {
    // Summary is count of items in this field
    return complexObject.length;
  } else {
    // Summary is value of this field
    return complexObject;
  }
}

/**
 * Get a value for the path from a complex object
 * @param complexObject Usually JSON schema as a complex object
 * @param dotPath Property path in dot notation e.g "mysection.myproperty"
 */
export function getSchemaValueFromDotPath(
  complexObject: JSONSchema7,
  dotPath: string
): JSONSchema7 {
  const props = dotPath.split("."); // split property names

  for (let i = 0; i < props.length; i++) {
    if (typeof complexObject !== "undefined") {
      const regex = /^(?<index>\d+)$/;
      const match = props[i].match(regex);
      // path element is an array index
      if (match) {
        const index = match.groups?.index;
        if (!(complexObject as JSONSchema7)["items"]) {
          throw new Error("'items' not found in object");
        }
        if (index) {
          complexObject = (complexObject as JSONSchema7)["items"] as JSONSchema7; // go to "items" array
          complexObject = (complexObject as JSONSchema7Array)[+index] as JSONSchema7; // go to array element
        }
      }
      // path element is a property
      else {
        if (!(complexObject as JSONSchema7)["properties"]) {
          throw new Error("'properties' not found in object");
        }
        complexObject = (complexObject as JSONSchema7)["properties"] as JSONSchema7; // go to "properties"
        complexObject = (complexObject as JSONSchema7Object)[props[i]] as JSONSchema7; // go next level
      }
    }
  }
  return complexObject as JSONSchema7;
}

/**
 * Get an array of paths in dot notation for a complex object
 * @param complexObject Usually a complex object
 */
export function getDotPathFromObject(complexObject: object): string[] {
  return paths(complexObject);
}

export function addDot(a: object | string | undefined, b: string) {
  return a ? `${a}.${b}` : b;
}

export function paths(o: any, head: string | undefined = undefined): string[] {
  return Object.entries(o).reduce((accumulator: string[], [key, value]: [string, unknown]) => {
    let fullPath = addDot(head, key);
    if (value) {
      return isObject(value)
        ? accumulator.concat(head || [], paths(value, fullPath))
        : accumulator.concat(fullPath);
    }
    return accumulator.concat([]);
  }, []);
}

export function getJsonDataLevel(dataObject: Record<string, unknown>, level: number) {
  for (let i = 0; i <= level; i++) {
    if (typeof dataObject !== "undefined") {
      const [firstKey] = Object.keys(dataObject);
      // go next level of first key
      dataObject = dataObject[firstKey] as Record<string, unknown>;
    }
  }
  return dataObject;
}

export function getJsonSchemaLevel(jsonSchemaObject: JSONSchema7, level: number) {
  for (let i = 0; i <= level; i++) {
    if (typeof jsonSchemaObject !== "undefined") {
      if (jsonSchemaObject["properties"]) {
        // go next level of "properties"
        jsonSchemaObject = (jsonSchemaObject as JSONSchema7Object)["properties"] as JSONSchema7;
      } else {
        const [firstKey] = Object.keys(jsonSchemaObject);
        // go next level of first key
        jsonSchemaObject = (jsonSchemaObject as JSONSchema7Object)[firstKey] as JSONSchema7;
      }
    }
  }
  return jsonSchemaObject;
}

export function getSchemaProperties(schema: JSONSchema7): string[] {
  const props: string[] = [];
  if (schema?.properties) {
    Object.entries(schema.properties).forEach(([sectionKey, sectionSchema]) => {
      if ((sectionSchema as JSONSchema7)?.properties) {
        const sectionProperties = (sectionSchema as JSONSchema7).properties as JSONSchema7;
        Object.keys(sectionProperties).forEach(key => {
          props.push(`${sectionKey}.${key}`);
        });
      }
    });
  }
  return props;
}

export function getFormattedJsonSchema(complexObject: any): string {
  return JSON.stringify(complexObject, undefined, 4);
}

export function getObjectJsonSchema(formatted: string): any {
  try {
    return {
      ok: true,
      data: JSON.parse(formatted)
    };
  } catch (error) {
    return {
      ok: false,
      error
    };
  }
}

export const mergeSchemaAtTargetProperty = (
  target: any,
  source: any,
  targetPropertyKey: string
) => {
  const output = Object.assign({}, target);
  if (isObject(target) && isObject(source["properties"])) {
    _.set(output, `properties.${targetPropertyKey}.properties`, source["properties"]);
  }
  return output;
};
