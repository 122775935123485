import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "@vp/shared/authentication";
import { IS_IVY_API } from "@vp/shared/guards";
import { APIM_SUB_KEY, APIM_TRACE, IVY_SUB_KEY, IVY_SUFFIX, OKTA_ROUTES } from "@vp/shared/tokens";
import { EMPTY, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class AuthenticationHeaderInterceptor implements HttpInterceptor {
  constructor(
    @Inject(OKTA_ROUTES) private oktaApiRoutes: string[],
    @Inject(IVY_SUFFIX) private ivySuffix: string,
    @Inject(IVY_SUB_KEY) private ivyApimSubscriptionKey: string,
    @Inject(IS_IVY_API) private readonly isIvyApi: boolean,
    @Inject(APIM_TRACE) private readonly apimTrace: string,
    @Inject(APIM_SUB_KEY) private readonly apimSubscriptionKey: string,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = new URL(request.url);

    //only add APIM and Token headers if its our API
    if (!this.oktaApiRoutes.find(x => url.origin.includes(x))) {
      return next.handle(request);
    }

    //handle Ivy Token headers
    if (this.isIvyApi) {
      let apiPath = request.url;
      if (url.hostname !== "localhost") {
        const rootPath = url.pathname.split("/")[1];
        apiPath = request.url.replace(rootPath, this.ivySuffix);
      }

      const token = this.authenticationService.generateIvyToken();
      if (!token) {
        return EMPTY;
      }
      const ivyRequest = request.clone({
        url: apiPath,
        headers: request.headers
          .set("Ocp-Apim-Subscription-Key", this.ivyApimSubscriptionKey)
          .set("Ocp-Apim-Trace", this.apimTrace)
          .set("Authorization", "Bearer " + token)
      });
      return next.handle(ivyRequest);
    }

    this.authenticationService.isLoggedIn$().subscribe(authenticatedResult => {
      if (!authenticatedResult.isAuthenticated && !this.isIvyApi) {
        if (
          request.urlWithParams.includes("callback") ||
          request.urlWithParams.includes("silent-renew")
        ) {
          this.authenticationService.login();
        }

        if (!this.authenticationService.isLoggingOut) {
          this.router.navigate(["/home"]);
        }
      }
    });

    return this.authenticationService.getAccessToken().pipe(
      mergeMap((token: string | null) => {
        if (token) {
          // Clone the request to add the new header
          const clonedRequest = request.clone({
            headers: request.headers
              .set("Ocp-Apim-Subscription-Key", this.apimSubscriptionKey)
              .set("Ocp-Apim-Trace", this.apimTrace)
              .set("Authorization", "Bearer " + token)
              .set("x-ui-local-offset", new Date().getTimezoneOffset().toString())
          });
          return next.handle(clonedRequest);
        } else {
          // If the token is not available, return the original request
          return next.handle(request);
        }
      })
    );
  }
}
