<mat-card>
  <mat-card-title>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div *ngIf="to.label && to.hideLabel !== true">{{ to.label }}</div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <button mat-flat-button type="button" color="primary" (click)="add()" *ngIf="!to.disabled">
          {{ addTooltip | memoize: to }}
        </button>
      </div>
    </div>
  </mat-card-title>
  <mat-card-subtitle>
    <div fxLayout="column" fxLayoutAlign="start stretch">
      <div>
        {{ to.description }}
      </div>
      <div class="mat-error" role="alert" *ngIf="showError && formControl.errors">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
    </div>
  </mat-card-subtitle>

  <div *ngIf="dataSource && columnDefs" mat-card-image>
    <mat-table
      class="form-field-small"
      [dataSource]="dataSource"
      [style.width.%]="100"
      matSort
      [multiTemplateDataRows]="true"
      [trackBy]="trackByIndex"
    >
      <!--column defs-->
      <ng-container
        *ngFor="let col of columnDefs; trackBy: trackByKey"
        [matColumnDef]="asString(col.key)"
      >
        <mat-header-cell *matHeaderCellDef>{{ col.templateOptions?.label }}</mat-header-cell>
        <mat-cell *matCellDef="let row; let dataIndex = dataIndex">
          <formly-field [field]="getField | memoize: field:col:dataIndex"></formly-field>
        </mat-cell>
      </ng-container>

      <!--action column def-->
      <ng-container matColumnDef="actions" *ngIf="!to.disabled">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell
          *matCellDef="let row; let dataIndex = dataIndex"
          fxLayout="row"
          fxLayoutAlign="end center"
        >
          <button
            mat-icon-button
            type="button"
            (click)="remove(dataIndex)"
            *ngIf="!to.disabled"
            [matTooltip]="removeTooltip | memoize: dataIndex:model"
          >
            <mat-icon color="warn">delete_forever</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <!--header output-->
      <mat-header-row
        *matHeaderRowDef="getColumns | memoize: columnKeys:to.disabled"
      ></mat-header-row>

      <!--body output-->
      <mat-row
        *matRowDef="let row; columns: getColumns | memoize: columnKeys:to.disabled"
        fxLayout="row"
        fxLayoutAlign="start center"
      ></mat-row>
    </mat-table>
  </div>
</mat-card>
