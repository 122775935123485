export interface InteractiveChannel {
  id: string;
  provider: InteractiveChannelProvider;
  interactiveChannelId: string;
  hostId: string;
  topic: string;
  alternativeHosts: string[];
  startUrl: string;
  joinUrl: string;
  isActive: boolean;
  registeredUsers: RegisteredUser[];
  interactiveSessions: InteractiveSession[];
}

export enum InteractiveChannelProvider {
  Zoom = 1
}

export interface RegisteredUser {
  firstName: string;
  lastName: string;
  email: string;
  joinUrl: string;
}

export interface InteractiveSession {
  interactiveChannelId: string;
  uuid: string;
  start_time: string;
  end_time: string;
  duration: number;
  participants: InteractiveSessionParticipant[];
}

export interface InteractiveSessionParticipant {
  id: string;
  name: string;
  email: string;
}
