import { Component, ChangeDetectionStrategy } from "@angular/core";
import { FieldType, FormlyFieldConfig } from "@ngx-formly/core";

@Component({
  selector: "lib-formly-flex-layout-type",
  templateUrl: "./formly-flex-layout-type.component.html",
  styleUrls: ["./formly-flex-layout-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFlexLayoutTypeComponent extends FieldType {
  fxFlexDefault = 100;

  getFlex(field: FormlyFieldConfig): number {
    if (field?.templateOptions?.fxFlex) {
      return field?.templateOptions?.fxFlex;
    }
    return this.fxFlexDefault;
  }

  trackById(index: number, item: FormlyFieldConfig) {
    return item.id ?? index;
  }
}
