/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { PageResult } from "@vp/models";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";

export function formatError(error: any) {
  return throwError(error.message);
}

export class UrlBuilder {
  constructor(public baseUrl: string, public keys: string[] = []) {}

  build(routeParameters: { [key: string]: any }, queryParameters: { [key: string]: any }): string {
    return this.cleanUnused(this.buildFromParams(routeParameters, queryParameters));
  }

  buildUrlWithQueryParameters(queryParams: { [key: string]: any }) {
    return this.build({}, queryParams);
  }

  buildFromParams(
    routeParams: { [key: string]: any },
    queryParams: { [key: string]: any }
  ): string {
    const builtUrl = Object.keys(routeParams).reduce(
      (url, key) =>
        url.replace(new RegExp(`/{${key}}`), routeParams[key] ? `/${routeParams[key]}` : ""),
      this.baseUrl
    );
    const queryString = this.buildQueryString(queryParams);
    if (queryString.length > 0) {
      return `${builtUrl}?${queryString}`;
    }
    return builtUrl;
  }

  buildQueryString(query: { [key: string]: any }) {
    return Object.keys(query)
      .filter(key => !!query[key] || typeof query[key] === "boolean")
      .map(key => {
        const arrayParts: string[] = [];
        const value = query[key];
        if (Array.isArray(value) && value.length > 0) {
          value.forEach(v => {
            arrayParts.push(`${key}=${v}`);
          });
          return arrayParts.join("&");
        } else {
          return `${key}=${query[key]}`;
        }
      })
      .join("&");
  }

  cleanUnused(url: string): string {
    const urlObj = new URL(url);
    for (const [key, value] of urlObj.searchParams.entries()) {
      if (!value) {
        urlObj.searchParams.delete(key);
      }
    }
    return urlObj.toString();
  }
}

export function createUrlBuilder(url: string, keys?: string[]): UrlBuilder {
  return new UrlBuilder(url, keys);
}

export function extractMatchingHeaders(headers: HttpHeaders): Record<string, unknown> {
  return headers.keys().reduce<Record<string, unknown>>((accumulatedObject, key) => {
    if (key.startsWith("x-")) {
      const _key = key.slice(2);
      const _propertyName = convertKebabCaseToCamelCase(_key);
      let value = headers.get(key);
      value = parseHeaderValue(value);
      accumulatedObject[_propertyName] = value;
    }
    return accumulatedObject;
  }, {});
}

function parseHeaderValue(value: string | null): any {
  if (value === null) {
    return value;
  }
  if (value.toLowerCase() === "true") {
    return true;
  }
  if (value.toLowerCase() === "false") {
    return false;
  }
  const numberValue = parseFloat(value);
  if (!isNaN(numberValue)) {
    return numberValue;
  }
  return value;
}

export function withHeaderData(): (
  source: Observable<HttpResponse<unknown>>
) => Observable<[HttpResponse<unknown>, Record<string, unknown>]> {
  return source =>
    source.pipe(
      map((response: HttpResponse<unknown>) => {
        const headerData = extractMatchingHeaders(response.headers);
        return [response, headerData];
      })
    );
}

export function mapToPageResult<T>(): (
  source: Observable<[HttpResponse<unknown>, Record<string, unknown>]>
) => Observable<PageResult<T>> {
  return source =>
    source.pipe(
      map(([response, headers]) => {
        return {
          results: (response.body as T[]) || [],
          partialResult: response.status === 206,
          totalRecords: headers.totalRecords
        } as PageResult<T>;
      })
    );
}

// pageResult.results = response.body || [];
// pageResult.partialResult = response.status === 206;
// return pageResult;

export function hasPropertyIgnoringCase(obj: any, prop: string): boolean {
  return Object.keys(obj).some(k => k.toLowerCase() === prop.toLowerCase());
}

export function convertKebabCaseToCamelCase(input: string): string {
  return input
    .split("-")
    .reduce(
      (result, word, index) =>
        result + (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)),
      ""
    );
}
