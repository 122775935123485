import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { AddOrEditSnippetDialogModule } from "@vp/user-snippets/ui/add-or-edit-snippet-dialog";
import { UserSnippetsModule } from "@vp/user-snippets/ui/user-snippets";
import { InsertSnippetComponent } from "./insert-snippet.component";

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    FlexLayoutModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    AddOrEditSnippetDialogModule,
    UserSnippetsModule
  ],
  declarations: [InsertSnippetComponent],
  exports: [InsertSnippetComponent]
})
export class InsertSnippetModule {}
