import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { FormlyModule } from "@ngx-formly/core";
import { ESignatureDialogComponent } from "./e-signature-dialog/e-signature-dialog.component";
import { UserESignatureComponent } from "./user-e-signature.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    FlexLayoutModule,
    FormlyModule.forChild({
      types: [{ name: "e-signature", component: UserESignatureComponent }]
    })
  ],
  declarations: [UserESignatureComponent, ESignatureDialogComponent],
  exports: [UserESignatureComponent]
})
export class UserESignatureModule {}
