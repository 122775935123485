import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Role } from "@vp/models";
import { LocalStorageData, LocalStorageService } from "@vp/shared/local-storage";
import { AppStoreService } from "@vp/shared/store/app";
import { RouterUtilities } from "@vp/shared/utilities";
import { Observable } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";

export const ROUTE_DEFAULT = "/home";

@Injectable({
  providedIn: "root"
})
export class RedirectGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly appStoreService: AppStoreService,
    private localStorageService: LocalStorageService,
    private routerUtilities: RouterUtilities
  ) {
    localStorageService.load();
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.appStoreService.selectedRole.pipe(
      withLatestFrom(this.localStorageService.localStorage$),
      map(([role, localStorage]: [Role, LocalStorageData | null]) => {
        if (localStorage?.data?.redirect === true) {
          const pathname = localStorage.data?.pathname;
          const fragment = localStorage.data?.fragment?.replace("#", "");
          const search = RouterUtilities.parseQueryString(localStorage.data?.search);
          const url = new URL(pathname, window.location.origin);
          const pathParts = url.pathname.split("/");
          this.localStorageService.clearAll();

          return this.router.createUrlTree(pathParts, {
            queryParams: search,
            fragment: fragment
          });
        }
        const { path, queryParams } = this.routerUtilities.getRouteDefaultFromRole(role as Role);

        return this.router.createUrlTree([path], {
          queryParams: queryParams
        });
      })
    );
  }
}
