import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from "@ngx-formly/core";
import { mergeDeep } from "@vp/shared/utilities";

@Component({
  selector: "lib-formly-object-type",
  templateUrl: "./formly-object-type.component.html",
  styleUrls: ["./formly-object-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyObjectTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  flexTo: any = {};

  flexTemplateDefaults = {
    fxFlex: "100",
    fxLayout: "column",
    fxLayoutAlign: "start stretch",
    fxLayoutGap: "16px grid"
  };

  ngOnInit(): void {
    this.flexTo = mergeDeep(this.flexTemplateDefaults, this.props, "replace");
  }

  trackById = (index: number, item: FormlyFieldConfig) => {
    return item.id ?? index;
  };
}
