<ng-container [ngTemplateOutlet]="template"> </ng-container>

<ng-template #template let-tagGroups="tagGroups">
  <form [formGroup]="formGroup">
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <ng-container *ngFor="let childType of tagTypes; let i = index">
        <mat-form-field fxFlex appearance="fill" *ngIf="i === 0" class="custom-selector-field">
          <mat-label>{{ tagTypes[0] | tagTypeDisplayName | async }}</mat-label>
          <mat-select
            panelClass="custom-select-panel"
            [formControl]="formControls[tagTypes[0]]"
            multiple
            (selectionChange)="onTagChanged($event.value, tagTypes[0])"
          >
            <ng-container *ngFor="let tagGroup of getParentGroup(childType)">
              <mat-option [value]="tagGroup.tag.tagId">
                {{ tagGroup.tag.displayName }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex appearance="fill" *ngIf="i > 0" class="custom-selector-field">
          <mat-label>{{ childType | tagTypeDisplayName | async }}</mat-label>
          <mat-select
            panelClass="custom-select-panel"
            [formControl]="formControls[childType]"
            multiple
            (selectionChange)="onTagChanged($event.value, childType)"
            [disabled]="getSelectedGrouping(childType).length === 0"
          >
            <ng-container *ngFor="let tagGroup of getSelectedGrouping(childType)">
              <mat-optgroup [label]="tagGroup.tag.displayName">
                <mat-option
                  *ngFor="let childTagGroup of getChildrenOfType(tagGroup.children, childType)"
                  [value]="childTagGroup.tag.tagId"
                >
                  {{ childTagGroup.tag.displayName }}
                </mat-option>
              </mat-optgroup>
              <mat-divider></mat-divider>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </div>
  </form>
</ng-template>
