<ng-container
  *ngIf="{
    disabled: disabled | async
  } as vm"
>
  <div class="mb-3">
    <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="30px">
      <mat-hint class="mat-hint" *ngIf="to.label && to.hideLabel !== true">{{ to.label }}</mat-hint>
      <vp-insert-snippet
        *ngIf="showSnippet() && !vm.disabled"
        (insertSnippetEvent)="insertSnippet($event)"
      ></vp-insert-snippet>
    </div>

    <angular-editor
      [lib-DisableEditor]="vm.disabled ?? false"
      [config]="config"
      [formlyAttributes]="field"
      [formControl]="formControl"
      #editor
      (focusout)="saveSelection()"
      (keyup)="saveSelection()"
    >
    </angular-editor>
    <mat-hint *ngIf="to.description && to.hideDescription !== true">{{ to.description }}</mat-hint>
  </div>
</ng-container>
