import { Guid } from "guid-typescript";

export interface IEvent {
  eventId: Guid;
  eventTime: Date;
  handled: boolean;
  sender: string;
  args: any;
}

export class EventBase<T extends any> implements IEvent {
  readonly eventId: Guid;
  readonly eventTime: Date;
  handled!: boolean;

  sender!: string;
  args!: T;

  constructor() {
    this.eventTime = new Date();
    this.eventId = Guid.create();
    this.handled = false;
  }
}
