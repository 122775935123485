<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    availableTags: (availableTags$ | async) ?? []
  }"
></ng-container>

<ng-template
  #template
  let-availableTags="availableTags"
>
<img [src]="getImagePath()" class="image-icon" [ngClass]="{
  'image-icon-xl': imgSizeXl === true,
  'image-icon-lg': imgSizeLg === true
}" />
<mat-form-field fxFlex="calc(50% - 16px)">
  <mat-label [innerText]="label"></mat-label>
  <mat-select
    #caseTypeSelect
    [formControl]="formControl"
    [required]="to.required || false"
    [value]="to.value"
  >
    <mat-option [value]="null" *ngIf="availableTags.length > 1">{{ noneSelectedText }}</mat-option>
    <mat-option *ngFor="let tag of availableTags" [value]="tag.tagId">
      {{ tag.displayName }}
    </mat-option>
  </mat-select>
</mat-form-field>
</ng-template>
