<div fxLayout="column" fxLayoutAlign="start stretch">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-form-field fxFlex="50">
      <mat-label>Filter Existing or Add New</mat-label>
      <input matInput type="text" [(ngModel)]="value" (keyup)="applyFilter()" />
      <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value = ''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button
      class="add-button"
      *ngIf="validForm"
      mat-raised-button
      color="primary"
      (click)="submit()"
    >
      Add
    </button>
  </div>

  <div fxFlex fxLayoutGap="16px">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="value">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="nogrow">Value</mat-header-cell>
        <mat-cell *matCellDef="let data" fxFlex="nogrow">
          {{ data.value }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions" stickyEnd="true">
        <mat-header-cell *matHeaderCellDef fxLayoutAlign="end center">Actions</mat-header-cell>
        <mat-cell *matCellDef="let data; let i = index" fxLayoutAlign="end center">
          <button mat-icon-button aria-label="Delete forever icon" (click)="remove(data.index)">
            <mat-icon color="warn" class="mat-icon-action">delete_forever</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns | async"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns | async"></mat-row>
    </mat-table>
  </div>
</div>
