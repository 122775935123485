<div>
  <textarea
    title="Text Area"
    matInput
    [id]="id"
    [readonly]="to.readonly"
    [required]="to.required || false"
    [formControl]="formControl"
    [cols]="to.cols"
    [rows]="to.rows"
    [formlyAttributes]="field"
    [placeholder]="to.placeholder ?? ''"
    [tabindex]="to.tabindex"
    [cdkTextareaAutosize]="to.autosize"
    [cdkAutosizeMinRows]="to.autosizeMinRows"
    [cdkAutosizeMaxRows]="to.autosizeMaxRows"
    [class.cdk-textarea-autosize]="to.autosize"
    [errorStateMatcher]="textAreaErrorMatcher"
    #text
  ></textarea>
  <mat-hint *ngIf="to.maxLength" fxLayout="row" fxLayoutAlign="end"
    >{{ text.value.length }} / {{ to.maxLength }}</mat-hint
  >
</div>
