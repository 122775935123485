export interface AcesoTvControlRequest {
  type: TvControlRequestType;
  locationID: string;
  cmd: TvControlCommand;
  value: string;
  patientID?: string;
  referenceID?: string;
}

export interface AcesoResponse {
  Status: string;
  referenceID: string;
  error: string;
}

export enum TvControlRequestType {
  tvControl = "tvControl"
}

export enum TvControlCommand {
  callLight = "CALLLIGHT",
  volume = "VOLUME",
  camPan = "CAMPANABS",
  camTilt = "CAMTILTABS",
  camZoom = "CAMZOOMABS"
}
