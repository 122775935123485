import { Component, ChangeDetectionStrategy } from "@angular/core";
import { FieldType, FormlyFieldConfig } from "@ngx-formly/core";

@Component({
  selector: "lib-formly-form-group-type",
  templateUrl: "./formly-form-group-type.component.html",
  styleUrls: ["./formly-form-group-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFormGroupTypeComponent extends FieldType {
  trackById(index: number, item: FormlyFieldConfig) {
    return item.id ?? index;
  }
}
